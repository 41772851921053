<template>
  <div class="badge">
    <component :is="svgComponent" />
  </div>
</template>

<script>
export default {
  name: 'Badge',
  props: {
    category: { type: String },
    type: { type: String }
  },
  components: {
    CorporateIdentity1: () => import(/* webpackChunkName: "badge-CorporateIdentity1" */ '@/assets/svg/badges/CI_1.svg'),
    CorporateIdentity2: () => import(/* webpackChunkName: "badge-CorporateIdentity2" */ '@/assets/svg/badges/CI_2.svg'),
    CorporateIdentity3: () => import(/* webpackChunkName: "badge-CorporateIdentity3" */ '@/assets/svg/badges/CI_3.svg'),

    Labels1: () => import(/* webpackChunkName: "badge-Labels1" */ '@/assets/svg/badges/LAB_1.svg'),
    Labels2: () => import(/* webpackChunkName: "badge-Labels2" */ '@/assets/svg/badges/LAB_2.svg'),
    Labels3: () => import(/* webpackChunkName: "badge-Labels3" */ '@/assets/svg/badges/LAB_3.svg'),

    Publishing1: () => import(/* webpackChunkName: "badge-Publishing1" */ '@/assets/svg/badges/PUB_1.svg'),
    Publishing2: () => import(/* webpackChunkName: "badge-Publishing2" */ '@/assets/svg/badges/PUB_2.svg'),
    Publishing3: () => import(/* webpackChunkName: "badge-Publishing3" */ '@/assets/svg/badges/PUB_3.svg'),

    Packaging1: () => import(/* webpackChunkName: "badge-Packaging1" */ '@/assets/svg/badges/PAC_1.svg'),
    Packaging2: () => import(/* webpackChunkName: "badge-Packaging2" */ '@/assets/svg/badges/PAC_2.svg'),
    Packaging3: () => import(/* webpackChunkName: "badge-Packaging3" */ '@/assets/svg/badges/PAC_3.svg'),

    HP: () => import(/* webpackChunkName: "badge-Packaging3" */ '@/assets/svg/badges/HP_Selected.svg')
  },
  computed: {
    svgComponent () {
      return this.type === 'hp' ? 'HP' : this.category + this.type
    }
  }
}
</script>

<style lang="scss" scoped>
.badge {
  width: 25vw;
  height: 25vw;

  @screen sm {
    width: 20vw;
    height: 20vw;
  }

  @screen md {
    width: rem(150px);
    height: rem(150px);
  }

  svg {
    width: 100%;
    height: 100%;
  }

  .cls-1 {
    fill: $c-white;
  }
}
</style>
